import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Website domain name sale/域名出售
        </p>
        <p>
          6jita.com
        </p>
        <p>6jita.cn</p>
        <a
          className="App-link"
          href="mailto:libmi@foxmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Send E-mail/发送邮件
        </a>
      </header>
    </div>
  );
}

export default App;
